import jwt_decode from "jwt-decode";
import axios from "axios";
import _ from "lodash-es";
import { store } from "../redux/store";
import { resetSnack, showSnack } from "../redux/actions/alertActions";
import { logout } from "../redux/actions/authActions";
import { BASE_URL, ENDPOINTS, IMAGE_BASE_URL } from "./constants";
import { refreshTokenSuccess } from "../redux/actions/authActions";
import Constants from "../utils/constants";

const options = {
  variant: "error",
};

const extractErrorMessage = (data) => {
  if (_.isString(data)) {
    return data;
  }
  if (data?.message) {
    return data.message;
  }
  if (!data.error) {
    return "Something went wrong";
  }

  if (!_.isString(data.error)) {
    console.log(data.error);
  }

  return data.error;
};

export const errorHandler = ({ status, data }) => {
  if (status === 401) {
    store.dispatch(
      showSnack({
        message: extractErrorMessage(data),
        options: { ...options, preventDuplicate: true },
      })
    );
    if (data?.data?.isToBeLogout) {
      store.dispatch(logout());
      store.dispatch(
        resetSnack()
      );
      window.location.href = "/login";
    }
  } else if (status === 404) {
    store.dispatch(
      showSnack({
        message: "We're sorry, but we couldn't find what you're looking for.",
        options,
      })
    );
  } else if (status >= 400 && status < 500) {
    store.dispatch(
      showSnack({
        message: extractErrorMessage(data),
        options: { ...options, preventDuplicate: true },
      })
    );
  } else {
    store.dispatch(
      showSnack({
        message: "Something went wrong. Please try again later.",
        options: { ...options, preventDuplicate: true },
      })
    );
  }
};

// Not using this function because incase of multiple request failed
// due to expire access token only one of them will continue with new
// access token all other requests will use previous access token
export const getToken = async () => {
  const state = store.getState();
  let {
    auth: { token },
  } = state;
  if (!token || !token.access.token || !token.refresh.token) {
    return null;
  }
  const decodedToken = jwt_decode(token.access.token);
  const date = new Date(0);
  date.setUTCSeconds(decodedToken.exp);
  if (date.valueOf() < new Date().valueOf()) {
    try {
      const result = await axios.post(`${BASE_URL}${ENDPOINTS.REFRESH_TOKEN}`, {
        refreshToken: token.refresh.token,
      });
      await store.dispatch(refreshTokenSuccess({ token: result.data.data }));

      token = result.data.data;
      return result.data.data.access.token;
    } catch (err) {
      errorHandler(err.response);
    }
  }
  return token.access.token;
};

export const uniqueValues = (arr, keyProps) => {
  const kvArray = arr.map((entry) => {
    const key = keyProps.map((k) => entry.headers[k]).join("|");
    return [key, entry];
  });
  const map = new Map(kvArray);
  return Array.from(map.values());
};

export function getImageUrl(appendString) {
  return appendString ? `${IMAGE_BASE_URL}/${appendString}` : "";
}

export const getRandomColor = () => {
  return Constants.RANDOM_COLORS[
    Math.floor(Math.random() * Constants.RANDOM_COLORS.length)
  ];
};
